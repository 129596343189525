//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {iField, iPopupPicker} from "@/icomponents";
import {miceService} from "../service/miceService";
import vueFilter from "@/commen/filter/vuefilter.js";
import {getCookie} from "tiny-cookie";

export default {
    components: {
        iField,
        iPopupPicker,
    },
    data() {
        return {
            timer: null,
            wrapperHeight: 0,
            eventForm: {
                name: "",
                mettingPassWord: "",
                mettingPriceId: "",
                typeDictVal: "",
                startTime: "",
                duration: "",
                durationCopy: "",
                dtEnd: "",
            },
            typeList: [],
            minuteList: [
                "00",
                "05",
                "10",
                "15",
                "20",
                "25",
                "30",
                "35",
                "40",
                "45",
                "50",
                "55",
            ],
            maxHour: 3,
            isEdit: false,
            tips: "",
            dtStart: "2020/02/01 00:00",
            dtEnd: "2020/02/01 23:59",
            status: -7, // -7 表示新增
        };
    },
    created() {
        this.init();
    },
    watch: {
        ["eventForm.durationCopy"]() {
            let data = this.eventForm.durationCopy.split(":");
            let data1 = Number(data[0]);
            let data2 = Number(data[1]);
            this.eventForm.duration = `${data1}小时${data2}分`;
            if (this.eventForm.startTime !== "") {
                this.getData(this.eventForm.startTime, this.eventForm.durationCopy);
            }
        },
        ["eventForm.startTime"]() {
            console.log("选择开始时间", this.eventForm.startTime);
            if (this.eventForm.durationCopy !== "") {
                this.getData(this.eventForm.startTime, this.eventForm.durationCopy);
            }
        },
        ["eventForm.typeDictVal"]() {
            let data = this.typeList.filter((ele) => {
                return ele.mettingTypeDesc == this.eventForm.typeDictVal;
            });
            this.miceTypeChange(data[0]);
        },
    },
    computed: {
        // 会议类型
        typeArr() {
            return this.typeList.map((ele) => {
                return ele.mettingTypeDesc;
            });
        },
        // 会议是否是进行中
        isShowEdit() {
            return this.status == 0 || this.status == 1 || this.status == -7;
        },
        isEditStartTime() {
            let result = true;
            if ([0, 1].includes(this.eventForm.status)) {
                if (
                    this.eventForm.startTime != "" &&
                    new Date(this.eventForm.startTime.split("-").join("/")) < new Date()
                ) {
                    result = false;
                }
            }
            return result;
        },
    },
    mounted() {
        let body_h =
            this.iStorage.get("_screen_height") ||
            document.documentElement.clientHeight ||
            window.innerHeight;
        let header_h = document.getElementsByClassName("header_position")[0]
            .clientHeight;
        this.wrapperHeight = body_h - 2 * header_h;
    },
    methods: {
        init() {
            this.getTypeList();
            this.getTips();
            let data = this.iStorage.get('eventData')
            /** 【差异化处理】  益普生传入eventData的dtStart和dtEnd格式与罗氏不一样。
             * 罗氏传入  dtStart: "2021-01-14"
             * 益普生传入  dtStart: "2021-01-11T00:00:00"
             */
            if (this.iStorage.get("tenant") == "ipsen") {
                data.dtStart = data.dtStart.substring(0, 10);
                data.dtEnd = data.dtEnd.substring(0, 10);
            }
            this.dtStart =
                new Date(data.dtStart.split("-").join("/") + " 00:00:00") < new Date()
                    ? vueFilter.timesFormatDate(
                    new Date().getTime(),
                    "yyyy/MM/dd hh:mm:ss"
                    )
                    : data.dtStart.split("-").join("/") + " 00:00:00";
            this.dtEnd =
                new Date(data.dtEnd.split("-").join("/") + " 23:59:59") <
                new Date(this.dtStart)
                    ? this.dtStart
                    : data.dtEnd.split("-").join("/") + " 23:59:59";
            console.log("设置的开始日期", this.dtStart);
        },
        computeHoursFunction(date, isToday, generateRange) {
            let startDate = new Date(
                `${new Date(this.dtStart).getFullYear()}/${
                    new Date(this.dtStart).getMonth() + 1
                }/${new Date(this.dtStart).getDate()} 00:00:00`
            ).getTime();
            let newDate = new Date(
                `${new Date().getFullYear()}/${
                    new Date().getMonth() + 1
                }/${new Date().getDate()} 00:00:00`
            ).getTime();
            let dateCopy = new Date(
                `${date.split("-").join("/")} 00:00:00`
            ).getTime();
            let result =
                (startDate === newDate && newDate === dateCopy) ||
                (startDate < newDate && newDate === dateCopy);
            if (result) {
                return generateRange(new Date().getHours(), 23);
            } else {
                return generateRange(0, 23);
            }
        },
        goBack() {
            this.iStorage.set("selectItem", "");
            this.$router.push("/");
        },
        clickRight() {
            this.debounce(this.saveForm, 500);
        },
        saveForm() {
            let verifyMap = ["name", "typeDictVal", "startTime", "duration"]; //必填项key集合
            let verify = verifyMap.some((ele) => {
                return !this.eventForm[ele];
            });
            if (verify) {
                this.$iToast("会议基本信息不完整");
                return false;
            }
            let eventForm = JSON.parse(JSON.stringify(this.eventForm));
            let eventData = this.iStorage.get('eventData') || JSON.parse(getCookie("eventData"))
            let data = this.eventForm.durationCopy.split(":");
            let data1 = Number(data[0]);
            let data2 = Number(data[1]);
            eventForm.duration = data1 * 60 + data2;

            eventForm.miceId = eventData.proposalId;

            if (
                this.eventForm.mettingPassWord &&
                this.eventForm.mettingPassWord.length < 6
            ) {
                this.$iToast("会议密码最少6位或不填写");
                return false;
            }
            if (eventForm.duration > 180 || eventForm.duration == 0) {
                this.$iToast(
                    eventForm.duration == 0 ? "会议时长不能等于0" : "会议时长最多3小时"
                );
                return false;
            }
            let reg = /^[0-9A-Za-z]{6,30}$/;
            let re = new RegExp(reg);
            if (
                eventForm.mettingPassWord &&
                eventForm.mettingPassWord.length > 0 &&
                !re.test(eventForm.mettingPassWord)
            ) {
                this.$iToast("会议密码最少为六位,数字或字母");
                return false;
            }
            if (
                new Date(eventForm.startTime.split("-").join("/")) < new Date() &&
                this.eventForm.status !== 2 &&
                this.isEditStartTime
            ) {
                this.$iToast("会议开始时间不能小于当前时间");
                return false;
            }
            let params = {
                MettingId: eventForm.mettingId,
                MettingPriceId: eventForm.mettingPriceId,
                MettingPassWord: eventForm.mettingPassWord,
                StartTime: eventForm.startTime,
                Duration: eventForm.duration,
                MiceId: eventForm.miceId,
                Remark: eventForm.remark,
                Name: eventForm.name,
                SourceType: 1
            };
            let miceServiceName = "";
            if (!this.isEdit && this.status == -7) {
                miceServiceName = "mettingadd";
                params.BeforeTime = 5;
            } else if (this.eventForm.status === 0) {
                miceServiceName = "mettingUpdate";
                params.BeforeTime = 5;
            } else if (this.eventForm.status === 1) {
                miceServiceName = "UpdateBookingMetting";
            } else if (this.eventForm.status === 2) {
                miceServiceName = "UpdateConveneMetting";
            }
            miceService[miceServiceName](params).then((res) => {
                if (res.success) {
                    this.$iToast(this.status == -7 ? "添加需求成功！" : "修改需求成功！");
                    this.$store.commit("setPublicValue", {
                        includes: " ",
                    });
                    this.goBack();
                }
            });
        },
        clickDurationCopy() {
            this.$refs.durationCopy.openPicker();
        },
        getData(date, duration) {
            date = date.split("-").join("/");
            let data = duration.split(":");
            let data1 = Number(data[0]);
            let data2 = Number(data[1]);
            let endDate = new Date(date);
            endDate.setHours(endDate.getHours() + data1);
            endDate.setMinutes(endDate.getMinutes() + data2);
            this.eventForm.dtEnd = this.getDataFormat(endDate);
        },
        miceTypeChange(data) {
            this.eventForm.mettingPriceId = data.priceId;
        },
        getTypeList() {
            let params = {
                sourcetype: 1,
            };
            miceService.mettingPriceQuery(params).then((res) => {
                if (res.success) {
                    this.typeList = res.content;
                    let data = this.iStorage.get("selectItem") || "";
                    if (data !== "") {
                        this.eventForm = data;
                        this.status = data.status;
                        this.isEdit = data.status !== 0;
                    }
                }
            });
        },
        getDataFormat(val) {
            let endDate = new Date(val);
            let month = endDate.getMonth() + 1;
            month = month >= 10 ? month : "0" + month;
            let day = endDate.getDate();
            day = day >= 10 ? day : "0" + day;
            return `${endDate.getFullYear()}-${month}-${day}  ${
                endDate.getHours() > 9 ? endDate.getHours() : "0" + endDate.getHours()
            }:${
                endDate.getMinutes() > 9
                    ? endDate.getMinutes()
                    : "0" + endDate.getMinutes()
            }`;
        },
        getTips() {
            miceService.getTips().then((res) => {
                if (res.success) {
                    this.tips = res.content;
                }
            });
        },
        /** 防抖函数 */
        debounce(fn, delay) {
            // fn只执行一次，变相实现第一次执行不延时
            fn();

            this.debounce = () => {
                if (this.timer != null) {
                    clearTimeout(this.timer);
                }
                // 如果已经执行过，不再执行

                this.timer = setTimeout(fn, delay);
            };
        },
    },
};
